import {
  isAssetTypeSubscription,
  Subscription,
} from '@/api/subscriptionPackages';
import { AuthorizableResourceCode } from '@/api/users';
import { Claims } from '@/auth/claims';
import { LoggedInUser } from '@/auth/user';
import {
  constantRoutes,
  hyvaAdAsyncRoutes,
  hyvaCustAsyncRoutes,
} from '@/router';
import store from '@/store';
import { AssetType } from '@/utils/assetTypes';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';
import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

// check resources required by page against resources the user has
export const equalOrContainsAny = <T extends string>(
  requiredCode: T | T[] | undefined,
  accessibleCodes: T[]
): boolean => {
  if (!requiredCode) return true;

  if (Array.isArray(requiredCode)) {
    return requiredCode.some((claim) => accessibleCodes.includes(claim));
  }

  return accessibleCodes.includes(requiredCode);
};

export const equalOrContainsAnyClaim = (
  requiredCode:
    | AuthorizableResourceCode
    | AuthorizableResourceCode[]
    | undefined,
  accessibleClaims: Claims
): boolean => {
  if (!requiredCode) return true;

  if (Array.isArray(requiredCode)) {
    return requiredCode.some((claim) => accessibleClaims.hasClaim(claim));
  }

  return accessibleClaims.hasClaim(requiredCode);
};

const hasPermission = (
  route: RouteConfig,
  moduleAndPageCodes: ALL_CLAIMS_CODES[],
  claims: Claims,
  subscriptions: Subscription[]
) => {
  let allowed: boolean = true;

  // check for user claims
  allowed &&= equalOrContainsAnyClaim(route.meta?.claim, claims);

  // check for dashboard menu items
  allowed &&= equalOrContainsAny(route.meta?.code, moduleAndPageCodes);

  // check for system features
  if (route.meta?.systemFeature) {
    allowed &&= hasSystemFeature(subscriptions, route.meta?.systemFeature);
  }

  return allowed;
};

export function hasSystemFeature(
  subscriptions: Subscription[],
  requiredFeature: SYSTEM_FEATURES | [SYSTEM_FEATURES | null, AssetType]
): boolean {
  let featureCode: SYSTEM_FEATURES | null = null;
  let assetType: AssetType = AssetType.All;

  if (Array.isArray(requiredFeature)) {
    featureCode = requiredFeature[0];
    assetType = requiredFeature[1];
  } else {
    featureCode = requiredFeature;
  }

  if (assetType != AssetType.All) {
    // TODO Optimize this? This function is called many times during route creation
    subscriptions = subscriptions
      .filter(isAssetTypeSubscription)
      .filter((sub) => sub.subscriptionPackageAssetType == assetType);
  }

  if (featureCode === null) {
    return Boolean(subscriptions.length);
  }

  return subscriptions
    .flatMap((sub) => sub.systemFeatures)
    .some(
      (feature) =>
        feature.code == featureCode && feature.value == true.toString()
    );
}

export const filterAsyncRoutes = (
  routes: RouteConfig[],
  moduleAndPageCodes: ALL_CLAIMS_CODES[],
  claims: Claims,
  subscriptions: Subscription[]
) => {
  const res: RouteConfig[] = [];
  for (const route of routes) {
    const r = { ...route };
    if (hasPermission(r, moduleAndPageCodes, claims, subscriptions)) {
      if (r.children) {
        r.children = filterAsyncRoutes(
          r.children,
          moduleAndPageCodes,
          claims,
          subscriptions
        );
      }
      res.push(r);
    }
  }
  return res;
};

export interface IPermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

export function generateRoutes(userInfo: LoggedInUser): RouteConfig[] {
  const applicableRoutes =
    userInfo.companyType === COMPANY_TYPE.Hyva
      ? hyvaAdAsyncRoutes
      : hyvaCustAsyncRoutes;
  return filterAsyncRoutes(
    applicableRoutes,
    userInfo.moduleAndPageCodes,
    userInfo.claims,
    userInfo.subscriptions
  );
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  /**
   * Combination of constantRoutes (404 etc) and dynamicRoutes (all others)
   */
  public routes: RouteConfig[] = [];

  /**
   * Routes actually available to the user.
   */
  public dynamicRoutes: RouteConfig[] = [];

  @Mutation
  public SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }
}

export const PermissionModule = getModule(Permission);
