// This file manages the state of the (KeyCloak) token, including periodic refresh.
//
// It does NOT take any explicit action when the token is obtained, refreshed, cleared etc.
// Executing such side-effect (or loading derived information) is performed elsewhere.
// See usage of `useTokenInfo()`.

import { UUID } from '@/api/common';
import { sleep } from '@/utils/promise';
import { distinct } from '@/utils/ref';
import { RemoveIndexSignature } from '@/utils/types';
import { COMPANY_TYPE, UserRole } from '@/utils/workData/lookuptable';
import { injectLocal, provideLocal } from '@vueuse/core';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { InjectionKey, onScopeDispose, readonly, Ref, ref } from 'vue';

export interface ProvideTokenOptions {
  /**
   * Refresh token if its validity becomes smaller
   * than the given amount of seconds.
   */
  remainingTokenValiditySeconds: number;
}

/**
 * User type as transported in Security Context.
 */
export enum SecurityContextUserType {
  ADMIN = 'ADMIN',
  REGULAR = 'REGULAR',
  DESIGNATED_USER = 'DESIGNATED_USER',
}

export interface TokenInfo {
  /**
   * JWT bearer token.
   */
  readonly bearerToken: string;

  /**
   * User's UUID.
   */
  readonly userId: UUID;

  /**
   * User's organization UUID.
   */
  readonly organizationId: UUID;

  /**
   * User's company UUID.
   */
  readonly companyId: UUID;

  /**
   * List of all organization UUID's this user has access too.
   */
  readonly allowedOrganizationIds: UUID[];

  /**
   * Type of user (admin, 'normal' customer or designated user).
   *
   * Note: use `claims` mechanism for access checking to modules, pages and actions,
   * not the userType.
   */
  readonly userType: SecurityContextUserType;

  /**
   * Role code of role assigned to this user.
   *
   * Note: don't use the role code for access checking, use `claims` mechanism instead.
   */
  readonly userRole: UserRole;

  /**
   * User's email address as registered in KeyCloak.
   */
  readonly email: string;

  /**
   * True when email address is marked as verified in KeyCloak.
   */
  readonly emailVerified: boolean;
}

type TokenParsed = RemoveIndexSignature<KeycloakTokenParsed> & {
  email: string;
  email_verified: boolean;
  ['security-context']: TokenSecurityContext;
};

interface TokenSecurityContext {
  userId: UUID;
  organizationId: UUID;
  allowedCompanyId: UUID;
  allowedOrganizationIds: UUID[];
  companyType: COMPANY_TYPE;
  userType: SecurityContextUserType;
  role: UserRole;
}

interface TokenState {
  keycloak: Keycloak;
  tokenInfo: Ref<TokenInfo | undefined>;
  updatTokenInfo: (value: TokenInfo | undefined) => void;
  terminated: boolean;
}

const tokenKey: InjectionKey<TokenState> = Symbol('token');

export function provideToken(
  keycloak: Keycloak,
  options: ProvideTokenOptions
): Ref<TokenInfo | undefined> {
  const tokenInfoRef = ref<TokenInfo>();
  const state: TokenState = {
    keycloak,
    updatTokenInfo: (value) =>
      (tokenInfoRef.value = value ? (readonly(value) as TokenInfo) : undefined),
    tokenInfo: distinct(tokenInfoRef),
    terminated: false,
  };

  const doHandleAuthChange = () => handleAuthChange(state);
  keycloak.onAuthLogout = doHandleAuthChange;
  keycloak.onAuthSuccess = doHandleAuthChange;
  keycloak.onAuthError = doHandleAuthChange;
  keycloak.onAuthRefreshError = doHandleAuthChange;
  keycloak.onAuthRefreshSuccess = doHandleAuthChange;
  keycloak.onTokenExpired = doHandleAuthChange;

  onScopeDispose(() => {
    state.terminated = true;
    keycloak.onAuthLogout = undefined;
    keycloak.onAuthSuccess = undefined;
    keycloak.onAuthError = undefined;
    keycloak.onAuthRefreshError = undefined;
    keycloak.onAuthRefreshSuccess = undefined;
    keycloak.onTokenExpired = undefined;
  });

  doHandleAuthChange();

  refreshTokenTask(state, options).catch((err) => {
    // Never happens (TM), any error checking happens in the 'task'
    console.error('Token refresh task crashed:', err);
  });

  provideLocal(tokenKey, state);
  return state.tokenInfo;
}

/**
 * Get access to Keycloak bearer token and other parsed information from the id token.
 */
export function useTokenInfo(): Ref<TokenInfo | undefined> {
  return useTokenState().tokenInfo;
}

/**
 * Obtain a function that, when called, will log out from Keycloak.
 *
 * The logout function can be called with a redirect URI that will be
 * used to get back to a specific page after the user has logged in
 * again. By default, it will redirect to the home page.
 */
export function useLogout(): (redirectUri?: string) => Promise<void> {
  const state = useTokenState();
  return (redirectUri) =>
    state.keycloak.logout({ redirectUri: redirectUri ?? window.origin });
}

/**
 * Obtain a function that, when called, will log in to Keycloak.
 *
 * The login function can be called with a redirect URI that will be
 * used to get back to a specific page after the user has logged.
 * By default, it will redirect to the home page.
 */
export function useLogin(): (redirectUri?: string) => Promise<void> {
  const state = useTokenState();
  return (redirectUri) =>
    state.keycloak.login({ redirectUri: redirectUri ?? window.origin });
}

function useTokenState(): TokenState {
  const state = injectLocal(tokenKey);
  if (!state) {
    throw new Error(
      "cannot determine token state, make sure you're calling this from setup() or a running effect scope"
    );
  }
  return state;
}

function handleAuthChange(state: TokenState): void {
  console.debug('Token state changed', {
    token: state.keycloak.token,
    tokenParsed: state.keycloak.tokenParsed,
    idTokenParsed: state.keycloak.idTokenParsed,
    refreshTokenParsed: state.keycloak.refreshTokenParsed,
  });
  const idTokenParsed = state.keycloak.idTokenParsed as TokenParsed | undefined;
  if (!state.keycloak.token || !idTokenParsed?.sub) {
    state.tokenInfo.value = undefined;
  } else {
    state.tokenInfo.value = {
      bearerToken: state.keycloak.token,
      userId: idTokenParsed.sub,
      organizationId: idTokenParsed['security-context'].organizationId,
      allowedOrganizationIds:
        idTokenParsed['security-context'].allowedOrganizationIds,
      companyId: idTokenParsed['security-context'].allowedCompanyId,
      userRole: idTokenParsed['security-context'].role,
      userType: idTokenParsed['security-context'].userType,
      emailVerified: idTokenParsed.email_verified,
      email: idTokenParsed.email,
    };
  }
}

async function refreshTokenTask(
  state: TokenState,
  options: ProvideTokenOptions
): Promise<void> {
  // Note: we could theoretically improve termination behavior,
  // but in our case, the app just never really unmounts anyway
  while (!state.terminated) {
    const ONE_MINUTE = 60 * 1000;
    await sleep(ONE_MINUTE);

    try {
      doRefreshToken(state, options);
    } catch (err) {
      console.warn('Token refresh failed, retrying:', err);
    }
  }
}

async function doRefreshToken(
  state: TokenState,
  options: ProvideTokenOptions
): Promise<void> {
  if (!state.keycloak.authenticated) {
    // No token to refresh
    // This is unlikely to happen for now, as we basically require
    // a token for everything, and will redirect away to keycloak
    // if we don't have one. But the app is made to handle this
    // gracefully, where we can e.g. show a dedicated page to the
    // user that he is logged out.
    return;
  }

  const refreshed = await state.keycloak.updateToken(
    options.remainingTokenValiditySeconds
  );
  // Note: any state change caused by this will be handled by
  // the event handlers installed on the keycloak instance.
  if (!state.keycloak.authenticated || !state.keycloak.tokenParsed) {
    // This can happen if the refresh got delayed for too long (e.g. laptop in standby),
    // or the refresh token itself is no longer valid.
    console.warn('Token no longer valid');
    return;
  }
  if (refreshed) {
    console.debug(
      'Token refreshed, valid until',
      new Date(state.keycloak.tokenParsed!.exp! * 1000)
    );
  }
}
