<script lang="ts">
import { createOrganization, getOrgById, updateOrg } from '@/api/organizations';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import CommonBtn from '@/components/button/CommonBtn.vue';
import newCard from '@/components/cusCard/NewCard.vue';
import i18n from '@/lang';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewOrg, { OrgForm } from './components/NewOrg.vue';

@Component({
  name: 'AddNewOrganization',
  components: {
    'new-card': newCard,
    'new-org': NewOrg,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop() orgId!: string;

  disabledTreeSel: boolean = false;
  saveOrCreate: boolean = false;
  isOrganizationDetailsLoading: boolean = false;
  title: string = '';
  activeName: string = 'first';
  orgForm!: OrgForm;

  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.orgForm = {
      name: '',
      street: '',
      district: '',
      province: '',
      city: '',
      country: '',
      zipCode: '',
      timezone: 'Europe/Amsterdam',
      companyId: UserModule.companyId,
      parentId: UserModule.organizationId,
      activationStatus: ACTIVATION_STATUS.Activated,
      note: '',
      parentName: '',
    };
    this.orgId && this.fetchOrgById();
    if (this.orgId) this.saveOrCreate = true;
  }

  handleClickTab(tab: any, event: any): void {}

  handleInput() {
    this.errorInfos = [];
  }

  /**
   * Handle organization operations: update, create
   */
  async handleOrganization(form: OrgForm) {
    try {
      if (this.orgId) {
        await updateOrg(this.orgId, form).then((res: any) => {
          if (!res) {
            customFailedMessage(
              this.$t('common.errorWithFetchingData') as string
            );
            return;
          }

          if (res.code === 200) {
            promptSuccessBox(this.$t('common.saved') as string);
            this.errorInfos = [];
            this.$router.push('/administration/index?activeName=orgTab');
            return;
          }

          promptFailedBox(this.$t('common.saved') as string);
          this.errorInfos = res.data.errors;
        });
        return;
      }

      await createOrganization(form).then((res: any) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          promptSuccessBox(this.$t('common.created') as string);
          this.errorInfos = [];
          this.$router.push('/administration/index?activeName=orgTab');
          return;
        }

        if (res.code === 400) {
          const fieldErrorCode = res.data.errors[0].code;
          if (fieldErrorCode === 'ApiErrorMaxOrganizationsExceeded') {
            customFailedMessage(
              this.$t('organizationModule.maximumOrgsExceeded') as string
            );
          }

          if (fieldErrorCode === 'ApiErrorSubscriptionPackageType') {
            customFailedMessage(
              this.$t('organizationModule.missingOrgSysFeature') as string
            );
          }
          this.errorInfos = res.data.errors;
          return;
        }

        promptFailedBox(this.$t('common.creation') as string);
        this.errorInfos = res.data.errors;
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Get organization by id if its in edit mode
   */
  async fetchOrgById() {
    try {
      this.isOrganizationDetailsLoading = true;
      const response = await getOrgById(this.orgId);
      this.orgForm = Object.assign({}, this.orgForm, response.data);
      this.disabledTreeSel = true;
      const title = i18n.t('common.editInterpolated', {
        name: response.data.name,
      });
      PageModule.setTitle(title);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationDetailsLoading = false;
    }
  }
}
</script>

<template>
  <new-card :createdOrEdit="false" v-loading="isOrganizationDetailsLoading">
    <el-tabs
      v-model="activeName"
      @tab-clicl="handleClickTab"
      style="margin: 0px 20px"
      class="org-tabs"
    >
      <el-tab-pane
        v-bind:label="$t('organizationModule.general')"
        name="first"
        style="overflow: auto"
      >
        <new-org
          :errorInfos="errorInfos"
          :disabledTreeSel="disabledTreeSel"
          :orgForm="orgForm"
          :saveOrCreate="saveOrCreate"
          :orgId="orgId"
          @handle-input="handleInput"
          @save-new-org="handleOrganization"
        ></new-org>
      </el-tab-pane>
      <!-- <el-tab-pane label="Work Schedule" name="second">Work Schedule</el-tab-pane> -->
    </el-tabs>
  </new-card>
</template>

<style lang="scss" scoped></style>
